<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <card-component title="Add Invoice" icon="ballot">
        <form @submit.prevent="submit">
          <b-field horizontal>
            <b-field>
              <b-input
                v-model="form.name"
                icon="account"
                placeholder="Full Name"
                name="name"
                required
              />
            </b-field>
            <b-field>
              <b-input
                v-model="form.email"
                icon="email"
                type="email"
                placeholder="E-mail"
                name="email"
                required
              />
            </b-field>
          </b-field>
          <b-field message="" horizontal>
            <b-field>
              <p class="control">
                <a class="button is-static"> +1 </a>
              </p>
              <b-input v-model="form.phone" type="tel" name="phone" expanded />
            </b-field>
          </b-field>
          <hr />
          <b-field message="Do not enter the leading zero" horizontal>
            <b-field>
              <p class="control">
                <a class="button is-static"> CA $ </a>
              </p>
              <b-input v-model="form.amount" type="tel" name="phone" expanded />
            </b-field>
          </b-field>

          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Submit</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-primary is-outlined" @click="reset"
                  >Reset</b-button
                >
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Forms',
  components: {
    CardComponent,
    TitleBar
  },
  data () {
    return {
      isLoading: false,
      form: {
        name: null,
        email: null,
        phone: null,
        department: null,
        subject: null,
        question: null
      },
      customElementsForm: {
        checkbox: [],
        radio: null,
        switch: true,
        file: null
      },
      departments: ['Business Development', 'Marketing', 'Sales']
    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'Add Invoice']
    }
  },
  methods: {
    submit () {},
    reset () {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === 'object') {
          return []
        }
        return null
      })

      this.$buefy.snackbar.open({
        message: 'Reset successfully',
        queue: false
      })
    }
  }
}
</script>
